import type { Moment } from "moment";
import type { NamespacePortfolioPropertyOpex } from "../user-preferences-helpers";
import { type CategoryCell, getDateRange, getDateRangeEntries, fitDateString, getBudgetDateRange } from "./portfolio-balance-helpers";
import type { Currency } from "../apollo/types";
import { arrayLast, hasValue } from "../common-helpers";
import type { OpexData } from "~/composables/queries/useOpexQuery";
import { MetricPeriodType } from "~/graphql/generated/graphql";
import { getBudgetCategoryMap, getCategories as getCategoriesBase, getSetValue, type Budget, type CategoryRow } from "./portfolio-category-helpers";

export type OpexCategoryDataPointValue = { accumulated: number; accumulatedAnnualised: number; value: number; valueAnnualised: number };

export type OpexCategoryBenchmarkDataPoint = {
  target: OpexCategoryDataPointValue;
  benchmark: OpexCategoryDataPointValue;
  diff: OpexCategoryDataPointValue;
  diffPercentage: OpexCategoryDataPointValue;
};

export type OpexCategoryData = { id: string; name: string; currency: Currency; values: OpexCategoryDataPointValue[]; accountHashes: string[] };

export type OpexCategoryBenchmarkData = {
  id: string;
  name: string;
  currency: Currency;
  accountHashes: string[];
  target: OpexCategoryDataPointValue[];
  benchmark: OpexCategoryDataPointValue[];
  diff: OpexCategoryDataPointValue[];
  diffPercentage: OpexCategoryDataPointValue[];
};

export const getOpexCategoryChartColumns = (preferences: NamespacePortfolioPropertyOpex, budget?: Budget) => {
  const withBudget = false;
  const periodType = withBudget ? MetricPeriodType.Monthly : preferences.periodType;

  const dateRange = getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);

  return getDateRangeEntries(dateRange, periodType);
};

export const getOpexCategoryBenchmarkData = (
  columns: Moment[],
  target: OpexData,
  benchmark: OpexData,
  preferences: { periodType: MetricPeriodType; normaliseBy: "area" | "tenancies" },
  budgets: Budget[],
  includeTotal = false
) => {
  const periodType = preferences.periodType;

  const periodTypeMonthCount = periodType === MetricPeriodType.Monthly ? 1 : periodType === MetricPeriodType.Quarterly ? 3 : 12;

  const normaliseByField: keyof OpexData["entityData"] = preferences.normaliseBy === "area" ? "totalArea" : "totalTenancies";

  const normaliseAverage = preferences.normaliseBy === "area" ? benchmark.entityData.averageArea : benchmark.entityData.averageTenancies ?? 1;

  const budgetRecordsMap = getBudgetCategoryMap(budgets, periodType);

  const getMonthDiff = (index: number) => Math.abs(columns[0].diff(columns[index], "months")) + periodTypeMonthCount;

  const getCategories = (data: CategoryCell[]) => getCategoriesBase(data, columns, budgetRecordsMap, periodType, true, "results");

  const getOpexDataRow = (categoryRow: CategoryRow): OpexCategoryData => ({
    id: categoryRow.id,
    currency: categoryRow.currency,
    name: categoryRow.name,
    accountHashes: categoryRow.hashes,
    values: categoryRow.values.map((v) => {
      const value = v.totalCostWithVat;

      return {
        value,
        accumulated: 0,
        accumulatedAnnualised: 0,
        valueAnnualised: 0,
      };
    }),
  });

  /** Normalises a category row by selected unit + create annualised value */
  const normaliseRow = (row: OpexCategoryData, data: OpexData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];

      current.value /= data.entityData[normaliseByField] || normaliseAverage;

      current.valueAnnualised = current.value * (12 / periodTypeMonthCount);
    }
  };

  /** Accumulates column values for a category row + the amount of 12 month periods of current accumulation. Will extrapolate if less than 12 */
  const accumulateRow = (row: OpexCategoryData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];
      const prev = row.values[i - 1];

      const monthDiff = getMonthDiff(i);

      const annualiseFactor = 12 / monthDiff;

      current.accumulated = current.value + (prev?.accumulated ?? 0);

      current.accumulatedAnnualised = current.accumulated * annualiseFactor;
    }
  };

  /** Iterate through all "cells" which hold accumulated data for a category in period  */
  const getCategoryRows = (data: OpexData, type: "target" | "benchmark") => {
    const rows = data.cells ? getCategories(data.cells).map((row) => getOpexDataRow(row)) : [];

    if (includeTotal) {
      rows.push({
        currency: rows[0]?.currency ?? "DKK",
        id: "total",
        name: "Total",
        accountHashes: [],
        values: rows.reduce((prev, next) => {
          for (let i = 0; i < next.values.length; i++) {
            if (!hasValue(prev[i])) prev[i] = { accumulated: 0, accumulatedAnnualised: 0, value: 0, valueAnnualised: 0 };

            prev[i].value += next.values[i].value;
          }

          return prev;
        }, [] as OpexCategoryDataPointValue[]),
      });
    }

    rows.forEach((row) => normaliseRow(row, data));

    rows.forEach(accumulateRow);

    return rows;
  };

  const targetRows = getCategoryRows(target, "target");
  const benchmarkRows = getCategoryRows(benchmark, "benchmark");

  /** Merging rows for benchmark */

  /** This is where most of the math happens */
  const getBenchmarkDataPoint = (
    benchmarkCategoryValue: OpexCategoryDataPointValue,
    targetCategoryValue: OpexCategoryDataPointValue = { accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 }
  ): OpexCategoryBenchmarkDataPoint => {
    const getDiffPercentage = (diff: number, total: number) => Math.sign(diff) * Math.abs((diff * 100) / total);

    const target = { ...targetCategoryValue };
    const benchmark = { ...benchmarkCategoryValue };
    const diff = {
      accumulated: target.accumulated - benchmark.accumulated,
      accumulatedAnnualised: target.accumulatedAnnualised - benchmark.accumulatedAnnualised,
      value: target.value - benchmark.value,
      valueAnnualised: target.valueAnnualised - benchmark.valueAnnualised,
    };
    const diffPercentage = {
      accumulated: getDiffPercentage(diff.accumulated, benchmark.accumulated),
      accumulatedAnnualised: getDiffPercentage(diff.accumulatedAnnualised, benchmark.accumulatedAnnualised),
      value: getDiffPercentage(diff.value, benchmark.value),
      valueAnnualised: getDiffPercentage(diff.valueAnnualised, benchmark.valueAnnualised),
    };

    return { benchmark, diff, diffPercentage, target };
  };

  const benchmarkDataRows: OpexCategoryBenchmarkData[] = [];

  for (let i = 0; i < benchmarkRows.length; i++) {
    const benchmarkRow = benchmarkRows[i];
    const targetRow = targetRows.find((c) => c.id === benchmarkRow.id);

    const targetRowValues: OpexCategoryDataPointValue[] = [];
    const benchmarkRowValues: OpexCategoryDataPointValue[] = [];
    const diffRowValues: OpexCategoryDataPointValue[] = [];
    const diffPercentageRowValues: OpexCategoryDataPointValue[] = [];

    for (let j = 0; j < benchmarkRow.values.length; j++) {
      const { benchmark, diff, diffPercentage, target } = getBenchmarkDataPoint(benchmarkRow.values[j], targetRow?.values[j]);

      targetRowValues.push(target);
      benchmarkRowValues.push(benchmark);
      diffRowValues.push(diff);
      diffPercentageRowValues.push(diffPercentage);
    }

    benchmarkDataRows.push({
      currency: benchmarkRow.currency,
      id: benchmarkRow.id,
      name: benchmarkRow.name,
      accountHashes: targetRow?.accountHashes ?? [],
      benchmark: benchmarkRowValues,
      diff: diffRowValues,
      diffPercentage: diffPercentageRowValues,
      target: targetRowValues,
    });
  }

  benchmarkDataRows.sort((a, b) => (a.id === "total" ? 1 : a.name.localeCompare(b.name)));

  return benchmarkDataRows;
};
